<template>
  <div v-if="show()">
    <b-navbar toggleable="md" type="dark" variant="dark" fixed="top">
      <b-navbar-brand :to="{ path: '/' }">
        <img src="../assets/images/newlogo.png" width="60" />
      </b-navbar-brand>

      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav v-if="ifPermission()">
          <!-- <b-nav-item :to="{ path: '/product/list-byGroup' }" data-cy="nav-bar-staff">
            <font-awesome-icon :icon="['fas', 'plus']" class="mr-1" />Products
          </b-nav-item> -->
          <b-nav-item :to="{ path: '/productsNew' }" data-cy="nav-bar-staff">
            <font-awesome-icon :icon="['fas', 'plus']" class="mr-1" />Products
          </b-nav-item>
          <b-nav-item :to="{ path: '/productCalendar' }" data-cy="nav-bar-staff">
            <font-awesome-icon :icon="['fas', 'calendar']" class="mr-1" />Calendar
          </b-nav-item>
          <b-nav-item :to="{ path: '/complain-tickets' }" data-cy="nav-bar-staff">
            <font-awesome-icon :icon="['fas', 'ticket-alt']" class="mr-1" />Tickets
          </b-nav-item>
        </b-navbar-nav>
        <b-navbar-nav :to="{ path: '/order' }" v-if="ifCallCenterExecutive()">
          <b-nav-item :to="{ path: '/order' }" data-cy="nav-bar-general-settings">
            <font-awesome-icon :icon="['fas', 'clipboard-list']" class="mr-1" /> Order List
          </b-nav-item>
          <b-nav-item v-if="!isNBL()" :to="{ path: '/logs' }" data-cy="nav-bar-general-settings">
            <font-awesome-icon :icon="['fas', 'phone']" class="mr-1" /> Call Logs
          </b-nav-item>
          <!-- <b-nav-item :to="{ path: '/product/list-byGroup' }" data-cy="nav-bar-staff">
            <font-awesome-icon :icon="['fas', 'plus']" class="mr-1" />Products
          </b-nav-item> -->
        </b-navbar-nav>
        <b-navbar-nav v-else>
          <b-nav-item :to="{ path: '/' }" v-if="showNavigation() && !isNBL()" data-cy="nav-bar-dashboard">
            <font-awesome-icon :icon="['fas', 'phone']" class="mr-1" />Call Logs
          </b-nav-item>
          <b-nav-item :to="{ path: '/calls' }" v-if="!showNavigation() && !isNBL()" data-cy="nav-bar-dashboard">
            <font-awesome-icon :icon="['fas', 'phone']" class="mr-1" />Call List
          </b-nav-item>
          <!-- Call Logs for Nature Basket -->
          <b-nav-item :to="{ path: '/logs' }" v-if="showNBNavigation() && !isNBL()" data-cy="nav-bar-dashboard">
            <font-awesome-icon :icon="['fas', 'clipboard-list']" class="mr-1" />Call Logs
          </b-nav-item>
          <!-- <b-nav-item :to="{ path: '/staff' }" data-cy="nav-bar-staff">
            <font-awesome-icon :icon="['fas', 'user']" class="mr-1" />Staff List
          </b-nav-item> -->

          <b-nav-item-dropdown right data-cy="nav-bar-manage-setting">
            <template v-slot:button-content>
              <font-awesome-icon :icon="['fas', 'users']" class="mr-1" />Staff
            </template>
            <b-dropdown-item :to="{ path: '/staff' }" data-cy="nav-bar-general-settings"><font-awesome-icon
                :icon="['fas', 'users']" class="mr-1" /> Staff List
            </b-dropdown-item>
            <b-dropdown-item :to="{ path: '/role' }" v-if="showNavigation() && !isSubAdmin()"
              data-cy="nav-bar-general-settings"><font-awesome-icon :icon="['fas', 'user']" class="mr-1" /> Roles
            </b-dropdown-item>
            <b-dropdown-item :to="{ path: '/store' }" v-if="showNavigation() || isNBLSubAdmin()"
              data-cy="nav-bar-general-settings"><font-awesome-icon :icon="['fas', 'store']" class="mr-1" /> Stores List
            </b-dropdown-item>
          </b-nav-item-dropdown>

          <!-- <b-nav-item :to="{ path: '/store' }" v-if="showNavigation()" data-cy="nav-bar-staff">
            <font-awesome-icon :icon="['fas', 'user']" class="mr-1" />Stores List
          </b-nav-item> -->
          <!-- <b-nav-item :to="{ path: '/order' }" data-cy="nav-bar-staff">
            <font-awesome-icon :icon="['fas', 'clipboard-list']" class="mr-1" />Order List
          </b-nav-item> -->
          <b-nav-item-dropdown right data-cy="nav-bar-manage-setting">
            <template v-slot:button-content>
              <font-awesome-icon :icon="['fas', 'clipboard-list']" class="mr-1" />Orders
            </template>
            <b-dropdown-item :to="{ path: '/order' }" data-cy="nav-bar-general-settings">
              <font-awesome-icon :icon="['fas', 'clipboard-list']" class="mr-1" /> Order List
            </b-dropdown-item>
            <b-dropdown-item v-if="!isNBL()" :to="{ path: '/b2b-order' }" data-cy="nav-bar-general-settings">
              <font-awesome-icon :icon="['fas', 'clipboard-list']" class="mr-1" /> B2B-Order List
            </b-dropdown-item>
            <b-dropdown-item v-if="!isNBL()" :to="{ path: '/order-recordings' }" data-cy="nav-bar-general-settings">
              <font-awesome-icon :icon="['fas', 'record-vinyl']" class="mr-1" /> Order Recordings
            </b-dropdown-item>
            <b-dropdown-item v-if="!isNBL()" :to="{ path: '/order-mis' }" data-cy="nav-bar-general-settings">
              <font-awesome-icon :icon="['fas', 'chart-line']" class="mr-1" /> POS Details
            </b-dropdown-item>
            <b-dropdown-item :to="{ path: '/order/report' }" data-cy="nav-bar-general-settings">
              <font-awesome-icon :icon="['fas', 'chart-line']" class="mr-1" /> Order Live Summary
            </b-dropdown-item>
            <b-dropdown-item v-if="!isNBL()" :to="{ path: '/sales/report' }" data-cy="nav-bar-general-settings">
              <font-awesome-icon :icon="['fas', 'chart-line']" class="mr-1" /> Sales Report
            </b-dropdown-item>
            <!-- <b-dropdown-item :to="{ path: '/b2b/report' }" data-cy="nav-bar-general-settings">
              <font-awesome-icon :icon="['fas', 'chart-line']" class="mr-1" /> B2B Report
            </b-dropdown-item> -->
            <b-dropdown-item v-if="!isNBL()" :to="{ path: '/sales/category-wise' }" data-cy="nav-bar-general-settings">
              <font-awesome-icon :icon="['fas', 'chart-line']" class="mr-1" /> Category Report
            </b-dropdown-item>
          </b-nav-item-dropdown>
          <!-- <b-nav-item :to="{ path: '/order-recordings' }" data-cy="nav-bar-staff">
            <font-awesome-icon :icon="['fas', 'record-vinyl']" class="mr-1" />Order Recordings
          </b-nav-item> -->
          <b-nav-item v-if="!isNBL()" :to="{ path: '/mis' }" data-cy="nav-bar-staff">
            <font-awesome-icon :icon="['fas', 'chart-pie']" class="mr-1" />MIS
          </b-nav-item>
          <b-nav-item v-if="!isNBL()" :to="{ path: '/target-upload' }" data-cy="nav-bar-staff">
            <font-awesome-icon :icon="['fas', 'upload']" class="mr-1" />Target Upload
          </b-nav-item>

          <b-nav-item :to="{ path: '/outboundCallerList' }" data-cy="nav-bar-staff">
            <font-awesome-icon :icon="['fas', 'upload']" class="mr-1" />OutBound
          </b-nav-item>
          <!-- <b-nav-item :to="{ path: '/offers' }" v-if="showNavigation()" data-cy="nav-bar-staff">
            <font-awesome-icon :icon="['fas', 'record-vinyl']" class="mr-1" />PD Offers
          </b-nav-item> -->
          <!-- <b-nav-item :to="{ path: '/productCalendar' }" v-if="ifPermission2()" data-cy="nav-bar-staff">
            <font-awesome-icon :icon="['fas', 'calendar']" class="mr-1" />Calendar
          </b-nav-item> -->
          <b-nav-item-dropdown v-if="ifPermission2() || isNBL()" right data-cy="nav-bar-manage-setting">
            <template v-slot:button-content>
              <font-awesome-icon :icon="['fas', 'clipboard-list']" class="mr-1" />More Functions
            </template>
            <!-- <b-dropdown-item
              :to="{ path: '/productCalendar' }"
              v-if="ifPermission2()"
              data-cy="nav-bar-general-settings"
            >
              <font-awesome-icon :icon="['fas', 'calendar']" class="mr-1" /> Calendar
            </b-dropdown-item>
            <b-dropdown-item :to="{ path: '/productBanner' }" v-if="ifPermission2()" data-cy="nav-bar-general-settings">
              <font-awesome-icon :icon="['fas', 'clipboard-list']" class="mr-1" /> Catalogue Banner
            </b-dropdown-item> -->
            <!-- <b-dropdown-item :to="{ path: '/offers' }" v-if="showNavigation()" data-cy="nav-bar-general-settings">
              <font-awesome-icon :icon="['fas', 'record-vinyl']" class="mr-1" /> PD Offers
            </b-dropdown-item> -->
            <b-dropdown-item :to="{ path: '/complain' }" v-if="ifTest()"
              data-cy="nav-bar-general-settings"><font-awesome-icon :icon="['fas', 'ticket-alt']" class="mr-1" />
              Support Tickets
            </b-dropdown-item>
            <b-dropdown-item :to="{ path: '/complain-tickets' }" data-cy="nav-bar-general-settings"><font-awesome-icon
                :icon="['fas', 'ticket-alt']" class="mr-1" /> Tickets
            </b-dropdown-item>
            <b-dropdown-item :to="{ path: '/productsNew' }" v-if="ifPermission2() && !isNBL()" data-cy="nav-bar-staff">
              <font-awesome-icon :icon="['fas', 'plus']" class="mr-1" />Products
            </b-dropdown-item>
          </b-nav-item-dropdown>
          <!-- <b-nav-item :to="{ path: '/product/list-byGroup' }" v-if="ifPermission2()" data-cy="nav-bar-staff">
            <font-awesome-icon :icon="['fas', 'plus']" class="mr-1" />Products
          </b-nav-item> -->
          <b-nav-item v-if="!isNBL()" :to="{ path: '/categoryList' }" data-cy="nav-bar-staff">
            <font-awesome-icon :icon="['fas', 'plus']" class="mr-1" />B2B-Product
          </b-nav-item>
          <!-- <b-nav-item :to="{ path: '/mis-table' }" data-cy="nav-bar-staff">
            <font-awesome-icon :icon="['fas', 'chart-pie']" class="mr-1" />Mis Table
          </b-nav-item> -->
          <!-- <b-nav-item :to="{ path: '/outbound-log' }" data-cy="nav-bar-staff">
            <font-awesome-icon :icon="['fas', 'phone']" class="mr-1" />Outbound
          </b-nav-item> -->
          <b-nav-item-dropdown right v-if="!showNavigation() && !isNBL()" data-cy="nav-bar-manage-setting">
            <template v-slot:button-content>
              <font-awesome-icon :icon="['fas', 'cog']" class="mr-1" />Settings
            </template>
            <b-dropdown-item :to="{ path: '/setting' }" v-if="!showNavigation()" data-cy="nav-bar-general-settings">
              General Settings
            </b-dropdown-item>
          </b-nav-item-dropdown>
        </b-navbar-nav>

        <!-- Right aligned nav items -->
        <b-navbar-nav class="ml-auto">
          <b-nav-item class="contactButton" target="_blank" @click="goToContactUs()">
            <font-awesome-icon :icon="['fas', 'envelope']" class="mr-1" /> Help
          </b-nav-item>
          <b-nav-item-dropdown right data-cy="nav-bar-manage-setting">
            <template v-slot:button-content>
              <font-awesome-icon :icon="['fas', 'user']" class="mr-1" />Profile
            </template>
            <b-dropdown-item data-cy="nav-bar-welcome-text" v-if="user">
              <font-awesome-icon class="mr-1" /> Welcome, {{ user.name.split(' ')[0] }}
            </b-dropdown-item>

            <b-dropdown-item target="_blank" link-classes="btn btn-grey mx-1"
              href="https://spencer-template.s3.ap-south-1.amazonaws.com/Spencers+IVR+ordering+CRM.pdf"
              data-cy="nav-bar-general-settings">
              <font-awesome-icon :icon="['fas', 'question-circle']" class="mr-1" />Documentation
            </b-dropdown-item>
            <b-dropdown-item :to="{ path: '/logout' }" link-classes="btn btn-grey mx-1" data-cy="nav-bar-logout">
              <font-awesome-icon :icon="['fas', 'sign-out-alt']" class="mr-1" /> Logout
            </b-dropdown-item>
          </b-nav-item-dropdown>
        </b-navbar-nav>
        -->
      </b-collapse>
    </b-navbar>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import User from '../model/user';

export default {
  name: 'NavBar',
  computed: {
    ...mapState('auth', ['user'])
  },
  methods: {
    showNBNavigation() {
      const filterSubAdmin = this.user.roles.filter(role => role.name === 'SUB_ADMIN_PD');
      if (
        this.user !== null &&
        Object.prototype.hasOwnProperty.call(this.user, 'type') &&
        this.user.type === 1 &&
        this.user.role !== User.userRole.administrator &&
        filterSubAdmin.length <= 0
      ) {
        return true;
      }
      return false;
    },
    goToContactUs() {
      window.open('/contactUs', '_blank');
    },
    showNavigation() {
      const filterSubAdmin = this.user.roles.filter(role => role.name === 'SUB_ADMIN_PD');
      if ((this.user && this.user.role === User.userRole.administrator) || filterSubAdmin.length > 0) {
        return true;
      }
      return false;
    },
    show() {
      if (this.user && this.user.role) {
        return true;
      }
      return false;
    },
    ifPermission() {
      if (this.user) {
        const user = this.user.roles.map(role => role.permissions);

        const newUser = user.map(permission => permission.map(permission => permission.name));
        const filteredUser = newUser.filter(permission => permission.includes('CREATE_CATALOGUE'));
        const filteredUser2 = newUser.filter(permission => permission.includes('MARKETING_APPROVER'));
        if (filteredUser.length > 0) {
          return true;
        }
        if (filteredUser2.length > 0) {
          return true;
        }
      }
      return false;
    },

    ifPermission2() {
      if (this.user) {
        const user = this.user.roles.map(role => role.permissions);
        const filterSubAdmin = this.user.roles.filter(
          role => role.name === 'SUB_ADMIN_PD' || role.name === 'STORE_SALES_MANAGER'
        );
        const newUser = user.map(permission => permission.map(permission => permission.name));
        const filteredUser = newUser.filter(permission => permission.includes('APPROVE_CATALOGUE'));
        if (filteredUser.length > 0 || filterSubAdmin.length > 0) {
          return true;
        }
      }
      return false;
    },
    ifCallCenterExecutive() {
      if (this.user) {
        const filterSubAdmin = this.user.roles.filter(role => role.name === 'CALL_CENTER_EXECUTIVE');
        if (filterSubAdmin.length > 0) {
          return true;
        }
      }
      return false;
    },

    isSubAdmin() {
      if (this.user) {
        const filterSubAdmin = this.user.roles.filter(role => role.name === 'SUB_ADMIN_PD');
        if (filterSubAdmin.length > 0) {
          return true;
        }
      }
      return false;
    },
    isNBLSubAdmin() {
      if (this.user) {
        // const isTypeNBL = this.user.type === 1;
        const filterSubAdmin = this.user.roles.filter(role => role.name === 'SUB_ADMIN');
        if (filterSubAdmin.length > 0) return true;
      }
      return false;
    },
    isNBL() {
      if (this.user) {
        const isTypeNBL = this.user.type === 1;
        return isTypeNBL;
      }
      return false;
    },
    // isNBL() {
    //   if (this.user) {
    //     const isTypeNBL = this.user.type === 1;
    //     const filterSubAdmin = this.user.roles.filter(role => role.name === 'SUB_ADMIN');
    //     return isTypeNBL && filterSubAdmin.length > 0;
    //   }
    //   return false;
    // },
    // isNBL_SSM() {
    //   if (this.user) {
    //     const isTypeNBL = this.user.type === 1;
    //     const hasStoreSalesManagerRole = this.user.roles?.some(role => role.name === 'STORE_SALES_MANAGER');
    //     return isTypeNBL && hasStoreSalesManagerRole;
    //   }
    //   return false;
    // },
    // isNBL_TC() {
    //   if (this.user) {
    //     const isTypeNBL = this.user.type === 1;
    //     const hasTelecallerRole = this.user.roles?.some(role => role.name === 'TELLECALLER');
    //     return isTypeNBL && hasTelecallerRole;
    //   }
    //   return false;
    // },
    ifTest() {
      const isTest = this.user.roles.filter(role => role.name === 'TEST_ROLE');
      if (isTest.length) {
        return true;
      }
      return false;
    }
  }
};
</script>
