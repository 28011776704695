export default class Store {
  // static storeType = {
  //   SRL: 0,
  //   NBL: 1
  // };

  // static nameStoreType = {
  //   0: 'SRL',
  //   1: 'NBL'
  // };

  constructor({
    id,
    index,
    storeName,
    storeid,
    cluster,
    region,
    format,
    pincodes,
    type,
    company,
    locations,
    isOnline
  }) {
    this.id = id || '';
    this.index = index + 1;
    this.storeName = storeName || '';
    this.storeid = storeid || '';
    this.cluster = cluster || '';
    this.region = region || '';
    this.format = format || '';
    this.pincodes = pincodes || '';
    this.company = company || '';
    this.type = type;
    this.isOnline = isOnline || false;
    // this.expand();
    this.locations = locations || '';
  }

  // expand() {
  //   this.typeName = Store.nameStoreType[this.type];
  // }
}
