import _ from 'lodash';
import storeService from '@/services/storeService';
import Store from '../../model/store';

const state = {
  baseUrl: '/store',
  storeList: [],
  regionList: [],
  clusterList: [],
  formatList: [],
  storeListDetail: [],
  store: null,
  loading: false
};

const actions = {
  getOne({ dispatch, commit }, { id, router }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');

    commit('setStore', { store: {} });

    storeService
      .getOne({ id, query: { type: 'all' } })
      .then(response => {
        commit('setStore', { store: response.data });
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },
  deleteOne({ dispatch, commit }, { type = 'store', id, router }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');

    storeService
      .deleteOne({ type, id })
      .then(_response => {
        commit('deleteStore', { id });
        dispatch(
          'alert/success',
          {
            showType: 'toast',
            position: 'center',
            title: '',
            text: type === 'store' ? 'Store has been deleted.' : 'Staff has been deleted.'
          },
          { root: true }
        );
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },
  postOne({ dispatch, commit }, { type = 'store', store, router, redirectUrl = '' }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');
    storeService
      .postOne({ type, store })
      .then(_response => {
        dispatch(
          'alert/success',
          {
            showType: 'toast',
            position: 'center',
            title: '',
            text: type === 'store' ? 'New store has been added.' : 'New store has been added.'
          },
          { root: true }
        );

        if (redirectUrl !== '') {
          router.push(redirectUrl);
        }
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },
  patchOne({ dispatch, commit }, { type = 'store', store, id, router, redirectUrl = '' }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');
    storeService
      .patchOne({ type, store, id })
      .then(_response => {
        dispatch(
          'alert/success',
          {
            showType: 'toast',
            position: 'center',
            title: '',
            text: type === 'store' ? 'Success' : 'Success'
          },
          { root: true }
        );

        if (redirectUrl !== '') {
          router.push(redirectUrl);
        }
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },
  listStore({ dispatch, commit }, { router, query }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');

    commit('setStores', { storeList: [] });

    storeService
      .listStore({ query })
      .then(response => {
        commit('setStores', { storeList: response.data });
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },
  listStoreDetail({ dispatch, commit }, { router, query }) {
    dispatch('alert/clear', {}, { root: true });
    commit('setStoresDetail', { storeListDetail: [] });

    commit('startRequest');

    storeService
      .listStore({ query: { ...query, type: 'all' } })
      .then(response => {
        commit('setStoresDetail', { storeListDetail: response.data });
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },
  listCluster({ dispatch, commit }, { router, query }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');

    commit('setClusters', { clusterList: [] });

    storeService
      .listStore({ query })
      .then(response => {
        commit('setClusters', { clusterList: response.data });
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },
  listFormat({ dispatch, commit }, { router, query }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');

    commit('setFormats', { formatList: [] });

    storeService
      .listStore({ query })
      .then(response => {
        commit('setFormats', { formatList: response.data });
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },
  listRegion({ dispatch, commit }, { router, query }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');

    commit('setRegions', { regionList: [] });

    storeService
      .listStore({ query })
      .then(response => {
        commit('setRegions', { regionList: response.data });
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  }
};

const getters = {};

const mutations = {
  startRequest(state) {
    state.loading = true;
  },
  requestFailed(state) {
    state.loading = false;
  },
  setStores(state, { storeList }) {
    if (storeList.length) {
      const storeOptions = storeList.map(store => ({ text: store, value: store }));
      state.storeList = storeOptions;
    }
    state.loading = false;
  },
  setClusters(state, { clusterList }) {
    if (clusterList.length) {
      const cluster = clusterList.map(store => ({ text: store, value: store }));
      state.clusterList = cluster;
    }
    state.loading = false;
  },
  setRegions(state, { regionList }) {
    if (regionList.length) {
      const region = regionList.map(store => ({ text: store, value: store }));
      state.regionList = region;
    }
    state.loading = false;
  },
  setStoresDetail(state, { storeListDetail }) {
    state.storeListDetail = _.map(storeListDetail, (store, index) => {
      const locationValue = Object.entries(store.locations)
        .map(([keys, value]) => `${keys}:${value}`)
        .join('\n');
      const newStore = new Store({
        index,
        id: store._id, // eslint-disable-line
        storeName: store.storeName,
        storeid: store.storeid,
        cluster: store.cluster,
        company: store.company,
        type: store.type,
        region: store.region,
        pincodes: store.pincodes ? store.pincodes.join(',') : '',
        format: store.format,
        isOnline: store.isOnline,
        locations: locationValue.includes('null') ? '**Please Update location' : locationValue
      });
      return newStore;
    });
    state.loading = false;
  },
  setStore(state, { store }) {
    const { _id } = store;
    state.store = new Store({
      id: _id,
      storeName: store.storeName,
      storeid: store.storeid,
      cluster: store.cluster,
      region: store.region,
      format: store.format,
      type: store.type,
      company: store.company,
      pincodes: store.pincodes ? store.pincodes : '',
      locations: store.locations ? store.locations : ''
      // lat: store.locations.lat,
      // long: store.locations?.long ? store.locations.long : ''
    });
    state.loading = false;
  },
  setFormats(state, { formatList }) {
    if (formatList.length) {
      state.formatList = formatList.map(item => ({ text: item, value: item }));
    }
    state.loading = false;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
